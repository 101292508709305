import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import {Observable} from "rxjs";
import {
  Credentials,
  JobApplication,
  Post,
  PostCategory,
  PostDetail,
  PostOption,
  PublicDetail,
  Token,
  Visitor,
  VisitorFavourites,
} from "../utilities/models";
import {environment} from "../../environments/environment";
import {CommonAPIOperations} from "../utilities/common-api-operations";

@Injectable({providedIn: 'root'})
export class AuthenticationService {

  constructor(protected httpClient: HttpClient) {
  }

  initiate(credentials: any): Observable<HttpResponse<any>> {
    const params = new HttpParams()
      .set('username', credentials['username'])
      .set('password', credentials['password']);

    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});

    return this.httpClient.post<HttpResponse<any>>('/visitor-service/oauth/initiate', params, {
      headers,
      observe: 'response'
    });
  }

  getToken(credentials: Credentials): Observable<Token> {
    const body = new HttpParams()
      .set('username', credentials.username)
      .set('password', credentials.password)
      .set('grant_type', 'password')
      .set('otp', '');

    const headers = new HttpHeaders({
      Authorization: 'Basic ' + btoa(environment.client + ':' + environment.secret),
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.httpClient.post<Token>('/visitor-service/oauth/token', body.toString(), {headers});
  }


  googleSignIn(visitor: Visitor, token: any): Observable<any> {
    return this.httpClient.post<any>('/visitor-service/oauth/googleSignIn?token=' + token, visitor);
  }

  validateEmail(visitor: Visitor): Observable<any> {
    return this.httpClient.post<any>('/visitor-service/oauth/validate', visitor);
  }

  logout(): Observable<void> {
    return this.httpClient.delete<void>('/visitor-service/oauth/logout');
  }

  sendVerifyOtp(refKey: string, to: string, notificationFormat: string): Observable<any> {
    return this.httpClient.post<any>('/visitor-service/oauth/sendVerifyOtp?refKey=' + refKey + '&to=' + to + '&notificationFormat=' + notificationFormat, '');
  }

  verifyOtp(refKey: string, otp: string, notificationFormat: string): Observable<any> {
    return this.httpClient.post<any>('/visitor-service/oauth/verifyOtp?refKey=' + refKey + '&otp=' + otp + '&notificationFormat=' + notificationFormat, '');
  }

  sendVerifyOtpLoggedVisitor(notificationFormat: string): Observable<any> {
    return this.httpClient.post<any>('/visitor-service/oauth/sendVerifyOtpRegisteredVisitor?notificationFormat=' + notificationFormat, '');
  }

  verifyOtpLoggedVisitor(otp: string, notificationFormat: string): Observable<any> {
    return this.httpClient.post<any>('/visitor-service/oauth/verifyOtpRegisteredVisitor?otp=' + otp + '&notificationFormat=' + notificationFormat, '');
  }

  sendPasswordRestOtp(email: string): Observable<any> {
    return this.httpClient.post<any>('/visitor-service/oauth/passwordResetOtp?email=' + email, '');
  }

  checkOtpIsValid(email: string, otp: string): Observable<any> {
    return this.httpClient.post<any>('/visitor-service/oauth/verifyPasswordResetOtp?email=' + email + '&otp=' + otp, '');
  }

  resetPassword(refKey: string, password: string): Observable<any> {
    const body = new HttpParams()
      .set('refKey', refKey)
      .set('password', password);

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post<any>('/visitor-service/oauth/resetPassword', body.toString(), {
      headers,
      observe: 'response'
    });
  }

}


@Injectable({providedIn: 'root'})
export class PostService extends CommonAPIOperations<Post, PostDetail, PostOption> {

  constructor(protected override httpClient: HttpClient) {
    super('Post', '/visitor-service/posts', httpClient);
  }

  fetchAllPosts(postCategory?: string, page?: number, size?: number): Observable<any> {
    return this.httpClient.get<any>(`${this._url}?origin=${window.location.hostname}&postCategory=${postCategory}&page=${page}&size=${size}&sort=createdDate,desc`);
  }

  postSearch(body: any, page?: number, size?: number): Observable<any> {
    return this.httpClient.get<any>(`${this._url}/search?postCategory=${body.postCategory}&origin=${window.location.hostname}&`
      + (body.keyword ? 'keyword=' + body.keyword + '&' : '')
      + (body.zip ? 'zip=' + body.zip + '&' : '')
      + (body.city ? 'city=' + body.city + '&' : '')
      + (body.postTypeName ? 'postTypeName=' + encodeURIComponent(body.postTypeName) + '&' : '')
      + (body.state ? 'state=' + body.state + '&' : '')
      + `&page=${page}&size=${size}`)
  }

  updatePostShareCount(postId: string) {
    return this.httpClient.patch<any>(`${this._url}/updateShareCount/${postId}`, '');
  }

}

@Injectable({providedIn: 'root'})
export class AttachmentService {

  constructor(protected httpClient: HttpClient) {
  }

  uploadJobApplication(formData: FormData, jobId: string, name: string) {
    return this.httpClient.post('/visitor-service/attachments/job/' + jobId + '/?name=' + name, formData, {responseType: 'text'});
  }

  getAttachment(postId: string, name: string): Observable<Blob> {
    return this.httpClient.get('/visitor-service/attachments/download/' + postId + '/?name=' + name, {responseType: 'blob'});
  }

  getOrganizationAttachment(orgId: string, name: string) {
    return this.httpClient.get(`/visitor-service/attachments/organization/download/${orgId}/?name=` + name, {responseType: 'blob'});
  }
}

@Injectable({providedIn: 'root'})
export class VisitorService extends CommonAPIOperations<Visitor, any, any> {

  constructor(protected override httpClient: HttpClient) {
    super('Visitor', '/visitor-service/visitors', httpClient);
  }

  updatePassword(oldPassword: string, newPassword: string): Observable<any> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('oldPassword', oldPassword);
    urlSearchParams.append('newPassword', newPassword);
    const headers: HttpHeaders = new HttpHeaders(
      {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    );
    return this.httpClient.post<any>(`${this._url}/updatePassword`, urlSearchParams.toString(), {
      headers: headers,
      observe: 'response'
    });
  }

  findOwn() {
    return this.httpClient.get<any>(`${this._url}/own`)
  }

}

@Injectable({providedIn: 'root'})
export class VisitorFavouriteService extends CommonAPIOperations<VisitorFavourites, any, any> {
  constructor(protected override httpClient: HttpClient) {
    super('VisitorFavourites', '/visitor-service/visitorFavourites', httpClient);
  }

  fetchAllFavourites(postCategoryList: PostCategory[], page: number, size: number): Observable<any> {
    return this.httpClient.get<any>(`${this._url}/?postCategories=${postCategoryList}&page=${page}&size=${size}`);
  }

  fetchFilterSearchAllFavourites(keyword: any, postCategoryList: PostCategory[], page: number, size: number): Observable<any> {
    return this.httpClient.get<any>(`${this._url}/search?keyword=${keyword}&postCategories=${postCategoryList}&page=${page}&size=${size}`);
  }
}

@Injectable({providedIn: 'root'})
export class PublicDetailService extends CommonAPIOperations<PublicDetail, any, any> {
  constructor(protected override httpClient: HttpClient) {
    super('PublicDetail', '/visitor-service/publicDetails', httpClient);
  }
}

@Injectable({providedIn: 'root'})
export class JobApplicationsService extends CommonAPIOperations<JobApplication, any, any> {
  constructor(protected override httpClient: HttpClient) {
    super('JobApplication', '/visitor-service/jobApplications', httpClient);
  }

  fetchAllAppliedJobs(keyword: any, page: number, size: number): Observable<any> {
    return this.httpClient.get<any>(`${this._url}?keyword=${keyword}&page=${page}&size=${size}`);
  }
}

@Injectable({providedIn: 'root'})
export class ZipCodeService {

  constructor(protected httpClient: HttpClient) {
  }

  fetchCitiesAndStatesByZipCode(zipCode: string): Observable<any> {
    return this.httpClient.get('/visitor-service/zipCode?zipCode=' + zipCode);
  }
}

@Injectable({providedIn: 'root'})
export class NotificationVisitorFilterService {

  constructor(protected httpClient: HttpClient) {
  }

  createVisitorFilter(visitorFilter: any) {
    return this.httpClient.post<any>('/visitor-service/notificationVisitorFilters', visitorFilter);
  }

  findByVisitorId(page: number, size: number) {
    return this.httpClient.get<any>(`/visitor-service/notificationVisitorFilters/findByVisitorId?page=${page}&size=${size}`);
  }

  deleteVisitorFilterById(id: string) {
    return this.httpClient.delete<any>('/visitor-service/notificationVisitorFilters/' + id);
  }

}

@Injectable({providedIn: 'root'})
export class NotificationService {

  constructor(protected httpClient: HttpClient) {
  }

  getNotification(notificationRequestStatus: string, page: string | number, size: string | number, sort: string) {
    return this.httpClient.get<any>('/visitor-service/notificationVisitors/' + notificationRequestStatus + '/?page=' + page + '&size=' + size + `&sort=${sort}`);
  }

  viewNotification(id: string) {
    return this.httpClient.post<any>('/visitor-service/notificationVisitors/' + id, '');
  }
}

@Injectable({providedIn: 'root'})
export class OrganizationService {

  constructor(protected httpClient: HttpClient) {
  }

  own(): Observable<HttpResponse<any>> {
    return this.httpClient.get<HttpResponse<any>>('/visitor-service/organizations/own?origin=' + window.location.hostname);
  }

}

@Injectable({providedIn: 'root'})
export class TlyService {

  constructor(protected httpClient: HttpClient) {
  }

  urlShort(url: string): Observable<any> {
    const urlSearchParams: URLSearchParams = new URLSearchParams();
    urlSearchParams.append('url', url);
    const headers: HttpHeaders = new HttpHeaders(
      {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    );
    return this.httpClient.post<any>('/visitor-service/shortUrl/', urlSearchParams.toString(), {
      headers: headers,
      observe: 'response'
    });
  }

}
