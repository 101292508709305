export const environment = {
  production: false,
  baseUrl: '',
  version: '1.0.0',
  client: 'localnotices',
  secret: 'l2ocalnotices',
  adminUrl: 'https://alpha-pa.localnotices.io/',
  visitorUrl: 'https://alpha-visitor.localnotices.io/',
  origin: 'alpha-visitor.localnotices.io',
  googleKey: 'AIzaSyBlvEjVjLQNB0nhZzOYQ6dfoXR5NTN0AZg',
  googleClientId: '96547190886-b099c48ch50q8a3fd4m8u5vk8tpgtjra.apps.googleusercontent.com'
};
