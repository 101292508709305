import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {InteractionService} from "./interaction.service";
import Swal from 'sweetalert2'
import {Router} from "@angular/router";
import {AuthenticationService} from "./api.service";
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {Dialog} from "@angular/cdk/dialog";
import {CommonTypes} from "../utilities/models";
import {isPlatformBrowser} from "@angular/common";
import {LocalStorageService} from "./local-storage.service ";

@Injectable({
  providedIn: 'root'
})
export class DataService {

  emailRegex: RegExp = /^(([^+<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordRegex: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/;
  logoutAPI!: Subscription;
  private readonly isBrowser: boolean = false;

  constructor(private router: Router,
              public dialog: Dialog,
              private interactionService: InteractionService,
              private authenticationService: AuthenticationService,
              private localStorageService: LocalStorageService,
              private translate: TranslateService,
              @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  /**
   * Displays a modal dialog with a "Processing..." message and a loading spinner.
   * The dialog does not allow clicks outside of it while it is open.
   */
  public processing() {
    Swal.fire(<any>{
      title: 'Processing...',
      allowOutsideClick: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      },
    });
  }

  /**
   * Logs the user out of the application by calling the logout API and clearing local storage data,
   * updating the user login status, closing any open dialogs and modals, setting the language back to English,
   and navigating back to the home page. If the user is not authorized, only local storage data is cleared.
   * Additionally, changes the body class from 'ca' to 'sa'.
   * @param isAuthorized A boolean indicating if the user is authorized (logged in)
   */
  logout(isAuthorized: boolean) {
    if (isAuthorized) {
      this.logoutAPI = this.authenticationService.logout().subscribe();
    }
    localStorage.clear();
    sessionStorage.clear();
    this.interactionService.updateUserLogin(false);
    this.dialog.closeAll();
    Swal.close();
    this.setLanguage('en');
    this.router.navigate(['/']);
    const element = document.querySelector('body');
    if (element != null) {
      let el = element.classList;
      el.replace('ca', 'sa');
    }
  }

  USPhoneValidationPattern() {
    return '^((\\([0-9]{3}\\))|[0-9]{3})[\\s\\-]?[\\0-9]{3}[\\s\\-]?[0-9]{4}$';
  }

  setLanguage(lang: string) {
    this.localStorageService.removeItem('ACTIVE_LANG');
    this.localStorageService.setItem('ACTIVE_LANG', lang);
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(lang);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(lang);
  }

  getLanguage(key: string) {
    return this.translate.instant(key);
  }

  /**
   * Returns the login status of the user by checking if the 'isLogged' item exists in the local storage and its value is 'LOGGED'.
   * @returns A boolean indicating if the user is logged in or not.
   */
  getLoginStatus() {
    if (this.isBrowser) {
      let item = this.localStorageService.getItem('isLogged');
      if (item !== undefined) {
        return item === CommonTypes.LOGGED;
      } else {
        return false;
      }
    }
    return false;
  }

  changeLang(lang: string) {
    this.localStorageService.setItem('ACTIVE_LANG', lang);
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
  }
}
