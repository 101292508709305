import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InteractionService {

  private _apiLoginUpdate = new Subject<boolean>();
  apiLoginUpdate = this._apiLoginUpdate.asObservable();
  private _postType = new Subject<string>();

  updateUserLogin(status: boolean) {
    this._apiLoginUpdate.next(status);
  }

  setPostType(type: string) {
    this._postType.next(type);
  }

}
